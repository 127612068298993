<template>
    <div class="sm:flex-grow-1 md:col-6 lg:col-8 xl:col-12">
    <div class="justify-content-center card">
    <Loader v-model="loading" />
    <div class="formgrid grid p-fluid">
      <div class="field col-12">
        <label for="price">Tipo Movimiento</label>
        <Dropdown 
        :disabled="actualRegister == null"
        v-model="register.type_movement" :options="typeMovements" 
        optionLabel="text" optionValue="value" />
      </div>
      <div class="field col-12">
        <label for="price">Billetes $500</label>
        <InputNumber v-model="register.cash500" id="price" integeronly showButtons buttonLayout="horizontal"
        :step="1" :min="0" decrementButtonClass="p-button-info" incrementButtonClass="p-button-info" 
        incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"/>
      </div>
      <div class="field col-12">
        <label for="price">Billetes $200</label>
        <InputNumber v-model="register.cash200" id="quantity" integeronly showButtons buttonLayout="horizontal"
        :step="1" :min="0" decrementButtonClass="p-button-info" incrementButtonClass="p-button-info" 
        incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"/>
      </div>
      <div class="field col-12">
        <label for="price">Billetes $100</label>
        <InputNumber v-model="register.cash100" id="quantity" integeronly showButtons buttonLayout="horizontal"
        :step="1" :min="0" decrementButtonClass="p-button-info" incrementButtonClass="p-button-info" 
        incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"/>
      </div>
      <div class="field col-12">
        <label for="price">Billetes $50</label>
        <InputNumber v-model="register.cash50" id="quantity" integeronly showButtons buttonLayout="horizontal"
        :step="1" :min="0" decrementButtonClass="p-button-info" incrementButtonClass="p-button-info" 
        incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"/>
      </div>
      <div class="field col-12">
        <label for="price">Billetes $20</label>
        <InputNumber v-model="register.cash20" id="quantity" integeronly showButtons buttonLayout="horizontal"
        :step="1" :min="0" decrementButtonClass="p-button-info" incrementButtonClass="p-button-info" 
        incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"/>
      </div>
      <div class="field col-12">
        <label for="price">Monedas</label>
        <InputNumber id="quantity" v-model="register.coins" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" />
      </div>
      <div class="field col-12">
        <label for="price">Total</label>
        <InputNumber :readonly="true" v-model="total" id="price" mode="currency" currency="USD" locale="en-US" />
      </div>
      <div class="field col-12">
        <Button
          icon="pi pi-money-bill"
          @click="save"
          style="width: 100%"
          label="Guardar"
        />
      </div>
    </div>
  </div>
  </div>
</template>
<script>
var math = require("mathjs");
import { register } from "../../models/register";
import Loader from "../../components/general/Loader.vue";
import { basicData } from '../../data/basicData';
import formMixin from '../../mixins/form';
export default {
    components: {Loader},
    mixins: [formMixin],
    data() {
        return {
            loading: false,
            actualRegister: null,
            register: new register()
        }
    },
    computed: {
      typeMovements() {
        if (this.actualRegister) 
          return [ {value: 2, text: 'ENTRADA'} , {value: 3, text: 'SALIDA'}, {value: 4, text: 'CIERRE'}];
        else
          return [{value: 1, text: 'APERTURA'}, {value: 2, text: 'ENTRADA'} , {value: 3, text: 'SALIDA'}, {value: 4, text: 'CIERRE'}];
      },
        total() {
            let cash500 = this.register.cash500 ? math.chain(this.register.cash500).multiply(500).done() : 0;
            let cash100 = this.register.cash100 ? math.chain(this.register.cash100).multiply(100).done() : 0;
            let cash200 = this.register.cash200 ? math.chain(this.register.cash200).multiply(200).done() : 0;
            let cash50 = this.register.cash50 ? math.chain(this.register.cash50).multiply(50).done() : 0;
            let cash20 = this.register.cash20 ? math.chain(this.register.cash20).multiply(20).done() : 0;
            let coins = this.register.coins ? this.register.coins : 0;
            return math.chain(cash500).add(cash200).add(cash100).add(cash50).add(cash20).add(coins).done();
        }
    },
    methods: {
        async save() {
            this.loading = true;
            try {
              if (!this.register.type_movement) 
                throw "Favor de escoger un tipo de movimiento"
              if (this.total == 0 && (this.register.type_movement == 2 || this.register.type_movement == 3))
                throw "No se puede realizar el movimiento con importe $0.00"
              this.register.id_register_parent = this.actualRegister ? this.actualRegister.id : null;
              this.register.import = this.total;
              this.register.status = 1;
              if (this.register.type_movement == 4) {
                let tickets = await new basicData(this.session).getTickets(this.register.id_register_parent);
                if (tickets.length > 0) 
                  throw "No se puede cerrar caja, hay tickets pendientes de cobrar"
              }
              await this.register.save();
              if (this.register.type_movement == 1) 
              {
                this.showSuccess("Caja abierta con exito!");
                this.$router.push('/');
              }
              this.register = new register(this.session);
            } catch (error) {
              this.showError(error);
            } finally {
                this.loading = false;
            }
        }
    },
    async mounted() {
        this.register = new register(this.session);
        this.loading = true;
        try {
          this.actualRegister = await new basicData(this.session).getSessionRegister();
        } catch (error) {
          this.showError(error, {
            type: 'info'
          });
          this.register.type_movement = 1;
        } finally {
          this.loading = false;
        }
    }
}
</script>

<style>
</style>

