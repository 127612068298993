import axios from "axios";

export class register
{
    constructor(session) {
        this.user = session ? session.user : null;
        this.id_company = session ? session.id_company : null;
        this.id_branch = session ? session.id_branch : null;
        this.token = session ? session.token : null;
        this.id = null;
        this.id_number = null;
        this.initial_date = null;
        this.final_date = null;
        this.tpv_user = null;
        this.comment = null;
        this.import = null;
        this.status = null;
        this.created_by = null;
        this.created_terminal = null;
        this.created = null;
        this.modified_by = null;
        this.modified_terminal = null;
        this.modified = null;
        this.type_movement = null;
        this.cash100 = null;
        this.cash1000 = null;
        this.cash20 = null;
        this.cash200 = null;
        this.cash50 = null;
        this.cash500 = null;
        this.difference = null;
        this.delivered_import = null;
        this.coins = null;
        this.other_payment = null;
        this.tries = null;
        this.id_register_parent = null;
        this.cards_confirmed = null;
        this.global = null;
        this.inventory_difference = null;
        this.inventory_missing = null;
        this.inventory_spare = null;
        this.external_session = null;
    }

    
    async save() {
        let response = await axios.post("tpv/register/save", this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }
}